import { Alert, Anchor, Button, Group, Skeleton, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconExclamationCircle } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCsrf } from '../../hooks/useCsrf';

import { claimDevice } from '../../data/Device';
import './DeviceForm.css';

const DeviceClaimForm: React.FC = () => {
    const { csrfToken } = useCsrf();
    const navigate = useNavigate();
    const { deviceId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            id: deviceId || '',
        },
        validate: {
            id: value => (value ? null : 'Device ID is required'),
        },
    });
    const icon = <IconExclamationCircle />;
    const handleBack = () => {
        navigate(`/devices`);
    };

    const handleSubmit = async (values: typeof form.values) => {
        setIsLoading(true);
        setErrorMessage('');
        try {
            await claimDevice(csrfToken, deviceId || values.id);
            setIsLoading(false);

            notifications.show({
                title: 'Device Claimed',
                message: `Successfully claimed device ${values?.id}`,
            });
            navigate(`/devices`);
        } catch (err: any) {
            setIsLoading(false);
            setErrorMessage(err?.message);
            console.error('Full object', err);
            console.error('err message', err?.message);
        }
    };

    const renderForm = (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
                <TextInput
                    withAsterisk
                    label="Id"
                    placeholder="12345a11-b22b-333c-44ab-7eb4fe0cd4ca"
                    description="The 32-character identifier for your new device. It should be printed on a sticker on the device case."
                    key={form.key('id')}
                    {...form.getInputProps('id')}
                />

                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={!form.isValid()} loading={isLoading}>
                        Claim
                    </Button>
                </Group>
                {errorMessage ? (
                    <Alert variant={'light'} color="red" title="Error" icon={icon}>
                        {errorMessage}
                    </Alert>
                ) : (
                    ''
                )}
            </Stack>
        </form>
    );

    return (
        <div className={'location-form'} style={{ paddingTop: '16px' }}>
            <Anchor onClick={handleBack}>Back</Anchor>
            <h2>Claim Device</h2>
            {deviceId && isLoading ? (
                <Stack align="flex-end">
                    <Skeleton height={32} mt={6} radius="md" />
                    <Skeleton height={32} mt={6} width="100" radius="md" />
                </Stack>
            ) : (
                renderForm
            )}
        </div>
    );
};

export default DeviceClaimForm;
