import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Group, TextInput, Stack, Alert, Anchor, Skeleton } from '@mantine/core';
import { useForm } from '@mantine/form';
import { apiClient } from '../../data/apiClient';
import { useCsrf } from '../../hooks/useCsrf';
import { IconExclamationCircle } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { JsonInput } from '@mantine/core';

import '../device/DeviceForm.css';
import useSWR from 'swr';
import {
    deviceConfigFetcher,
    updateDeviceConfigByDeviceId,
    updateDeviceConfigByStationId,
} from '../../data/DeviceConfig';
import type { DeviceConfig, DeviceConfigResponsePayload } from '../../data/DeviceConfig';
import { AxiosError } from 'axios';

const DeviceConfigForm: React.FC = () => {
    const { csrfToken } = useCsrf();
    const navigate = useNavigate();
    const { deviceId } = useParams();
    const [isCreating, setIsCreating] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            config: '',
        },
        // validate: {
        //   config: (value) => /* json validation? */,
        // },
    });
    const icon = <IconExclamationCircle />;
    const handleBack = () => {
        // TODO: this should direct to a different page depending on whether we're in "single device" mode or "station" mode
        navigate(`/devices`);
    };

    const {
        data: deviceConfigData,
        isLoading,
        mutate,
    } = useSWR<DeviceConfigResponsePayload, AxiosError>(
        deviceId ? `api/device/${deviceId}/config` : null,
        deviceConfigFetcher
    );

    useEffect(() => {
        if (deviceConfigData) {
            form.setValues({ config: JSON.stringify(deviceConfigData.config) });
        }
    }, [deviceConfigData]);

    const handleSubmit = async (values: typeof form.values) => {
        setIsCreating(true);
        setErrorMessage('');
        try {
            if (deviceId) {
                await updateDeviceConfigByDeviceId(csrfToken, deviceId, JSON.parse(values.config));
            } else {
                // TODO: if we're in "station" mode, use the /station/{stationId}/config endpoint
            }

            setIsCreating(false);
            mutate();
            if (deviceId) {
                notifications.show({
                    title: 'Configuration Updated',
                    message: `Successfully updated device configuration`,
                });
            } else {
                // TODO: handle "station" mode
            }
        } catch (err: any) {
            setIsCreating(false);
            setErrorMessage(`${err?.message}: ${JSON.parse(err?.response?.data).message}`);
            console.error('Full object', err);
            console.error('err message', err?.message);
        }
    };

    const renderForm = (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
                <JsonInput
                    withAsterisk
                    label="Configuration"
                    placeholder="{}"
                    description="A JSON configuration object for the device."
                    formatOnBlur
                    validationError="Invalid JSON"
                    autosize
                    minRows={15}
                    key={form.key('config')}
                    {...form.getInputProps('config')}
                />

                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={!form.isValid()} loading={isCreating}>
                        {deviceId ? 'Save' : 'Create'}
                    </Button>
                </Group>
                {errorMessage ? (
                    <Alert variant={'light'} color="red" title="Error" icon={icon}>
                        {errorMessage}
                    </Alert>
                ) : (
                    ''
                )}
            </Stack>
        </form>
    );

    return (
        <div className={'location-form'} style={{ paddingTop: '16px' }}>
            <Anchor onClick={handleBack}>Back</Anchor>
            <h2>{deviceId ? 'Edit device configuration' : 'Add a device'}</h2>
            {deviceId && isLoading ? (
                <Stack align="flex-end">
                    <Skeleton height={32} mt={6} radius="md" />
                    <Skeleton height={32} mt={6} width="100" radius="md" />
                </Stack>
            ) : (
                renderForm
            )}
        </div>
    );
};

export default DeviceConfigForm;
