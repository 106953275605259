import React, { useState, useEffect } from 'react';
import {
    Button,
    Paper,
    ActionIcon,
    Skeleton,
    Anchor,
    Flex,
    Modal,
    Stack,
    Text,
    Loader,
    NativeSelect,
    Breadcrumbs,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { listDeviceFetcher, Device, deleteDevice, unclaimDevice } from '../../data/Device';
import { AxiosError } from 'axios';
import { IconTrash, IconPencil, IconAdjustmentsFilled } from '@tabler/icons-react';
import { useCsrf } from '../../hooks/useCsrf';
import { notifications } from '@mantine/notifications';
import { listStationFetcher, Station } from '../../data/Station';
import { updateDevice } from '../../data/Device';
import { useAuth } from '../../hooks/useAuth';

const defaultDevice = {
    id: '',
    name: '',
};

type SelectOption = {
    value: string;
    label: string;
    disabled?: boolean;
};

type SelectOptionGroup = {
    group: string;
    items: SelectOption[];
};

// Got this from ChatGPT
// Basically we leverage reduce to snowball over the data and coerce it into
// the desired format. The first part of the callback we're ensuring that the
// group is set if not, then we add items to the group.
const groupByLocation = (data: any) => {
    // Group by locationId using a Map
    const grouped = data.reduce((acc: any, item: any) => {
        const locationId = item.locationId;
        const locationName = item.location?.name;

        if (!acc.has(locationId)) {
            acc.set(locationId, {
                group: locationName || 'Unknown Location',
                items: [],
            });
        }

        acc.get(locationId).items.push({
            label: item.name,
            value: item.id,
        });

        return acc;
    }, new Map());

    // Convert Map to an array
    return Array.from(grouped.values()) as SelectOptionGroup[];
};

const Devices: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { csrfToken } = useCsrf();
    const [deleteOpened, { open: openDelete, close: closeDelete }] = useDisclosure(false);
    const [unclaimOpened, { open: openUnclaim, close: closeUnclaim }] = useDisclosure(false);
    const [activeDevice, setActiveDevice] = useState(defaultDevice);
    const [isDeleting, setIsDeleting] = useState(false);
    const [locationOptions, setLocationOptions] = useState<(SelectOptionGroup | SelectOption)[]>([]);

    const handleNav = (route: string) => {
        navigate(route);
    };

    const {
        data: listDevicesResponse,
        isLoading,
        error,
        mutate,
    } = useSWR<Device[], AxiosError>('api/devices', listDeviceFetcher);

    const {
        data: listStationsResponse,
        isLoading: isLoadingStations,
        error: errorLoadingStations,
    } = useSWR<Station[], AxiosError>(`api/stations`, listStationFetcher);

    useEffect(() => {
        if (listStationsResponse) {
            const labelArray: (SelectOptionGroup | SelectOption)[] = groupByLocation(listStationsResponse);
            // Placeholder Group.
            labelArray.unshift({
                label: '-------',
                value: '',
            });
            setLocationOptions(labelArray);
        }
    }, [listStationsResponse]);

    const handleUnclaim = (device: Device) => {
        setActiveDevice(device);
        openUnclaim();
    };

    const handleDelete = (device: Device) => {
        setActiveDevice(device);
        openDelete();
    };

    /**
     * "Unclaim" will remove the device from the user's Org, making it available for another user to claim.
     */
    const handleUnclaimConfirmation = async () => {
        setIsDeleting(true);
        await unclaimDevice(csrfToken, activeDevice?.id);
        setIsDeleting(false);
        closeUnclaim();
        notifications.show({
            title: 'Device Removed',
            message: `Successfully removed device ${activeDevice?.name}.`,
        });
        setActiveDevice(defaultDevice);
        await mutate();
    };

    /**
     * "Delete" will permanently delete the device from the system - it will NOT be possible for another user to claim it.
     * This is an admin-only action.
     */
    const handleDeleteConfirmation = async () => {
        setIsDeleting(true);
        await deleteDevice(csrfToken, activeDevice?.id);
        setIsDeleting(false);
        closeDelete();
        notifications.show({
            title: 'Device Deleted',
            message: `Successfully deleted device ${activeDevice?.name}.`,
        });
        setActiveDevice(defaultDevice);
        await mutate();
    };

    const handleLocationSelection = async (deviceId: string, stationId: string) => {
        try {
            if (!stationId) return;
            await updateDevice(csrfToken, deviceId, { stationId });
            mutate();
            notifications.show({
                title: 'Device Assigned',
                message: `Successfully assigned device ${deviceId} to ${stationId}.`,
            });
        } catch (error: any) {
            notifications.show({
                title: 'Device Assignment Failed',
                message: error?.message,
            });
        }
    };

    const loadingIndicator = (
        <div>
            <Flex justify={'space-between'} align={'center'} style={{ marginTop: '16px', marginBottom: '16px' }}>
                <Skeleton height={38} width="100" radius="md" />
                <Skeleton height={32} width="100" radius="md" />
            </Flex>
            <Stack>
                <Skeleton height={76} width="100%" radius="md" />
                <Skeleton height={76} width="100%" radius="md" />
                <Skeleton height={76} width="100%" radius="md" />
            </Stack>
        </div>
    );

    if (isLoading) {
        return loadingIndicator;
    }
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <Modal opened={deleteOpened} onClose={closeDelete} title="Admin Delete">
                <Stack gap="sm">
                    <Text style={{ marginBottom: '16px' }}>
                        Are you sure you want to permanently delete device{' '}
                        <Text span fw={700}>
                            {activeDevice?.name}
                        </Text>{' '}
                        with ID{' '}
                        <Text span fw={700}>
                            {activeDevice?.id}
                        </Text>
                        ?
                    </Text>
                    <Button color="red" onClick={handleDeleteConfirmation} fullWidth>
                        {isDeleting ? <Loader color="gray" size={18} /> : 'Confirm Delete'}
                    </Button>
                    <Button color="gray" onClick={closeDelete} fullWidth>
                        Cancel
                    </Button>
                </Stack>
            </Modal>
            <Modal opened={unclaimOpened} onClose={closeUnclaim} title="Remove device from account">
                <Stack gap="sm">
                    <Text style={{ marginBottom: '16px' }}>
                        Are you sure you want to remove device{' '}
                        <Text span fw={700}>
                            {activeDevice?.name}
                        </Text>{' '}
                        from your account?
                    </Text>
                    <Button color="red" onClick={handleUnclaimConfirmation} fullWidth>
                        {isDeleting ? <Loader color="gray" size={18} /> : 'Confirm Delete'}
                    </Button>
                    <Button color="gray" onClick={closeUnclaim} fullWidth>
                        Cancel
                    </Button>
                </Stack>
            </Modal>
            <Breadcrumbs
                style={{ marginTop: '16px' }}
                separatorMargin="xs"
                separator={
                    <Text size="sm" fw={500}>
                        /
                    </Text>
                }
            >
                <Anchor size="sm" fw={500} onClick={() => handleNav('/')}>
                    Home
                </Anchor>
                <Text size="sm">Devices</Text>
            </Breadcrumbs>
            <Flex justify={'space-between'} align={'center'}>
                <h2>Devices</h2>
                <Flex justify={'flex-end'}>
                    <Button onClick={() => handleNav('/device/claim')} size={'xs'}>
                        Claim a Device
                    </Button>
                    {(user?.name === 'Evan Hsu' || user?.name === 'Scott Sword') && (
                        <Button onClick={() => handleNav('/devices/new')} size={'xs'} style={{ marginLeft: '10px' }}>
                            Register a new Device (Admin)
                        </Button>
                    )}
                </Flex>
            </Flex>
            {listDevicesResponse?.map(device => (
                <Paper key={device?.id} shadow="xs" p="lg" style={{ marginBottom: '16px' }}>
                    <Flex justify="space-between" align={'center'}>
                        <Anchor onClick={() => handleNav(`/device/${device?.id}/edit`)}>{device?.name}</Anchor>
                        <div>
                            <Flex align={'center'}>
                                {listStationsResponse?.length === 0 ? (
                                    <Text>
                                        No stations available.{' '}
                                        <Button
                                            style={{ margin: '0 10px' }}
                                            size={'xs'}
                                            color={'gray'}
                                            variant="light"
                                            onClick={() => handleNav(`/locations`)}
                                        >
                                            Create Station
                                        </Button>
                                    </Text>
                                ) : (
                                    <NativeSelect
                                        onChange={event =>
                                            handleLocationSelection(device?.id, event.currentTarget.value)
                                        }
                                        size="sm"
                                        style={{ marginRight: '10px' }}
                                        value={device.stationId ? device.stationId : ''}
                                        data={locationOptions}
                                    />
                                )}
                                <ActionIcon
                                    style={{ marginRight: '8px' }}
                                    variant="filled"
                                    color="gray"
                                    aria-label="Settings"
                                    onClick={() => handleNav(`/device/${device.id}/edit`)}
                                >
                                    <IconPencil style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                </ActionIcon>
                                <ActionIcon
                                    style={{ marginRight: '8px' }}
                                    variant="filled"
                                    color="gray"
                                    aria-label="Configuration"
                                    onClick={() => handleNav(`/device/${device.id}/config`)}
                                >
                                    <IconAdjustmentsFilled style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                </ActionIcon>
                                <ActionIcon
                                    variant="filled"
                                    color="gray"
                                    aria-label="Remove from account"
                                    onClick={() => handleUnclaim(device)}
                                >
                                    <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                </ActionIcon>
                                {/* Admin-only action */}
                                {/* <ActionIcon
                                    variant="filled"
                                    color="red"
                                    aria-label="Delete"
                                    onClick={() => handleDelete(device)}
                                >
                                    <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                </ActionIcon> */}
                            </Flex>
                        </div>
                    </Flex>
                </Paper>
            ))}
        </div>
    );
};

export default Devices;
