import { fetcher, apiClient } from './apiClient';

type PanelContentWithDuration = {
    /**
     * The name of a React component to render
     * i.e. 'UpcomingGames' | 'DraftKingsLogoBanner' | etc
     */
    component: string;

    /**
     * The props to pass to the React component. Valid values will vary depending on the Component.
     */
    params: Record<string, any> | null;

    /**
     * The number of seconds to display the attached content.
     * Setting this to 0 means that the content will be displayed indefinitely.
     * (This typically only makes sense to use when there's only a single item in the carousel array)
     */
    durationSeconds: number;
};
type CarouselConfig = PanelContentWithDuration[];

type PanelConfig = {
    /**
     * commercialTakeover: true means that the commercialContent will be displayed immediately whenever the "imageRoute" is "commercial".
     * Setting this to false disables the commercialContent altogether (meaning that the regular "content" carousel will continue
     * to be displayed even when the "imageRoute" is "commercial")
     *
     * Default: false
     */
    commercialTakeover: boolean;

    /**
     * An array of content to display when "isCommercial" is "commercial"
     */
    commercialContent: CarouselConfig;

    /**
     * An array of content to display when "isCommercial" is false, or when "commercialTakeover" is false
     */
    content: CarouselConfig;
};

type DeviceConfig = {
    /**
     * i.e. 'draft-kings-v1' | 'fanduel-v1' | etc
     */
    pluginId: string;

    topPanel: PanelConfig | null;
    middlePanel: PanelConfig | null;
    bottomPanel: PanelConfig | null;
};
/**
 * The shape of the API response
 */
type DeviceConfigResponsePayload = {
    id: string;
    deviceId: string;
    config: DeviceConfig;
    createdAt: string;
    updatedAt: string;
};

const deviceConfigFetcher = async (url: string): Promise<DeviceConfigResponsePayload> => {
    const response = await fetcher<DeviceConfigResponsePayload>(url);

    return {
        id: response.id,
        deviceId: response.deviceId,
        config: response.config,
        createdAt: response.createdAt,
        updatedAt: response.updatedAt,
    } as DeviceConfigResponsePayload;
};

const updateDeviceConfigByDeviceId = async (
    csrfToken: string | null,
    deviceId: string,
    payload: DeviceConfig
): Promise<null> => {
    if (csrfToken === null) {
        return null;
    }

    const response = await apiClient.put<null>(`api/device/${deviceId}/config`, JSON.stringify(payload), {
        headers: {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        },
    });

    return response.data;
};

const updateDeviceConfigByStationId = async (
    csrfToken: string | null,
    stationId: string,
    payload: DeviceConfig
): Promise<null> => {
    if (csrfToken === null) {
        return null;
    }

    const response = await apiClient.put<null>(`api/station/${stationId}/config`, JSON.stringify(payload), {
        headers: {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        },
    });

    return response.data;
};

export { deviceConfigFetcher, updateDeviceConfigByDeviceId, updateDeviceConfigByStationId };
export type { DeviceConfigResponsePayload, DeviceConfig };
