import { fetcher, apiClient } from './apiClient';

/**
 * The shape of the API response
 */
type Device = {
    id: string;
    name: string;
    stationId: string;
    orgId: string;
    createdAt: string;
    updateAt: string;
};

const deviceFetcher = async (url: string): Promise<Device> => {
    const deviceResponse = await fetcher<Device>(url);

    return {
        id: deviceResponse.id,
        name: deviceResponse.name,
        createdAt: deviceResponse.createdAt,
        updateAt: deviceResponse.updateAt,
        stationId: deviceResponse.stationId,
    } as Device;
};

const listDeviceFetcher = async (url: string): Promise<Device[]> => {
    return await fetcher<Device[]>(url);
};

const deleteDevice = async (csrfToken: string | null, deviceId: string): Promise<void> => {
    if (csrfToken === null) {
        return;
    }

    const deleteDeviceResponse = await apiClient.delete<void>(`api/device/${deviceId}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        },
    });
    return;
};

type UpdateDevicePayload = {
    name?: string;
    stationId?: string;
};

const updateDevice = async (
    csrfToken: string | null,
    deviceId: string | undefined,
    payload: UpdateDevicePayload
): Promise<Device | null> => {
    if (csrfToken === null) {
        return null;
    }

    const updateDeviceResponse = await apiClient.put<Device>(`api/device/${deviceId}`, JSON.stringify(payload), {
        headers: {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        },
    });

    return updateDeviceResponse.data;
};

/**
 * The "claim device" request has an empty payload.
 * The ID of the device to claim is passed in the URL and the org ID of the authenticated user will become the owner of the device.
 */
type ClaimDevicePayload = {};

const claimDevice = async (csrfToken: string | null, deviceId: string | undefined): Promise<Device | null> => {
    if (!csrfToken || !deviceId) {
        return null;
    }

    const claimDeviceResponse = await apiClient.post<Device>(
        `api/device/${deviceId}/claim`,
        {},
        {
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            },
        }
    );

    return claimDeviceResponse.data;
};

const unclaimDevice = async (csrfToken: string | null, deviceId: string): Promise<void> => {
    if (csrfToken === null) {
        return;
    }

    await apiClient.post<Device>(
        `api/device/${deviceId}/unclaim`,
        {},
        {
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            },
        }
    );

    return;
};

export { deviceFetcher, listDeviceFetcher, updateDevice, deleteDevice, claimDevice, unclaimDevice };
export type { Device, UpdateDevicePayload };
